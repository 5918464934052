import DoNotDisturbOn from '@material-symbols/svg-500/rounded/do_not_disturb_on.svg'
import { createColumnHelper } from '@tanstack/react-table'
import { capitalize, noop } from 'lodash'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useProjectId from 'src/components/hooks/useProjectId'
import SystemInspectorPanel from 'src/components/system/SystemInspectorPanel'
import SystemSelectModal from 'src/components/system/system-table/SystemSelectModal'
import { useSystemListFiltered } from 'src/query/systems/systems'
import { ISystem } from 'src/service/OrgTypes'
import { getProjectSystemTypes } from 'src/service/SystemService'
import { getStatusesForType } from 'src/service/SystemStatusService'
import {
  defaultFilterColumn,
  defaultTextColumn,
} from 'src/ui-elements/Table/Columns'
import Table from 'src/ui-elements/Table/Table'
import Badge from 'src/ui-elements/badge/Badge'
import Button from 'src/ui-elements/button/Button'
import { StatusEnum } from 'src/utility/statusEnums'

interface RequirementSystemsProps {
  parentFilter: Record<string, number[]>
  addSystems: (systemIds: number[]) => Promise<void>
  removeSystems: (systemIds: number[]) => void
}

const InspectorPanelSystemList: FC<RequirementSystemsProps> = ({
  parentFilter,
  addSystems,
  removeSystems,
}) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const systemColumnHelper = createColumnHelper<ISystem>()
  const [selectedSystemId, setSelectedSystemId] = useState<number | undefined>(
    undefined,
  )
  const [showAddSystemModal, setShowAddSystemModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const columns = [
    systemColumnHelper.accessor(
      'record_id',
      defaultTextColumn<ISystem>('record_id', {
        name: capitalize(t('system_number')),
      }),
    ),
    systemColumnHelper.accessor('name', {
      ...defaultTextColumn<ISystem>('name', {
        name: capitalize(t('system_name')),
      }),
      size: 80,
    }),
    systemColumnHelper.accessor('system_type', {
      ...defaultFilterColumn<ISystem>('system_type', {
        name: capitalize(t('system_type')),
        getFilter: () =>
          getProjectSystemTypes(projectId).then((systemTypes) =>
            systemTypes.map(({ type_code, id }) => ({
              name: type_code,
              value: id?.toString() ?? '0',
              active: true,
            })),
          ),
      }),
      size: 50,
      cell: (props) => (
        <span className="px-1 truncate">
          {props.getValue()?.type_code ?? ''}
        </span>
      ),
    }),
    systemColumnHelper.accessor('system_status', {
      ...defaultFilterColumn<ISystem>('system_status', {
        name: capitalize(t('status')),
        getFilter: () => {
          return getStatusesForType(projectId, 'System').then((statuses) =>
            statuses.map(({ name, id }) => ({
              name,
              value: id?.toString() ?? '',
              active: true,
            })),
          )
        },
      }),
      size: 130,
      cell: (props) => {
        const value = props.getValue()
        if (!value) return null
        const { name, status } = value
        return (
          <Badge
            status={
              Object.values(StatusEnum).includes(
                status.toUpperCase() as StatusEnum,
              )
                ? StatusEnum[status.toUpperCase() as StatusEnum]
                : undefined
            }
            text={name}
          />
        )
      },
    }),
  ]

  return (
    <>
      <Table
        name="SystemsInspectorPnael"
        columns={columns}
        onRowClick={(row: ISystem) => setSelectedSystemId(row.id)}
        onPreviewClick={(row: ISystem) => setSelectedSystemId(row.id)}
        useDataQuery={useSystemListFiltered}
        initialFilter={parentFilter}
        deleteIcon={DoNotDisturbOn}
        onDeleteClick={(row) => removeSystems([row.id])}
        tableButtons={(selectedSystems) => ({
          customButtons: [
            <Button
              key="add_systems"
              title={t('add_systems')}
              className={'h-8'}
              type={Button.ButtonType.PRIMARY}
              onClick={() => setShowAddSystemModal(true)}
            >
              {capitalize(t('add_systems'))}
            </Button>,
            <Button
              key="remove_systems"
              title={t('remove_systems')}
              type={Button.ButtonType.DANGER}
              className={'p-1 w-8 h-8'}
              disabled={selectedSystems.length < 1}
              onClick={() =>
                removeSystems(selectedSystems.map((system) => system.id))
              }
            >
              <DoNotDisturbOn
                className={twMerge(
                  'text-xl',
                  selectedSystems.length > 0 && 'fill-red',
                )}
              />
            </Button>,
          ],
        })}
      />
      {!!selectedSystemId && (
        <SystemInspectorPanel
          systemId={selectedSystemId}
          open={!!selectedSystemId}
          onClose={() => setSelectedSystemId(undefined)}
          onUpdate={noop}
        />
      )}
      {showAddSystemModal && (
        <SystemSelectModal
          tableName="selectSystems"
          onSubmit={async (ids) => {
            setLoading(true)
            await addSystems(ids)
            setLoading(false)
            setShowAddSystemModal(false)
          }}
          loading={loading}
          closeModal={() => setShowAddSystemModal(false)}
        />
      )}
    </>
  )
}

export default InspectorPanelSystemList
