import Circle from '@icons/circle.svg'
import Delete from '@icons/delete.svg'
import Workspaces from '@icons/workspaces.svg'
import { t, TFunction } from 'i18next'
import { isEqual } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import DateTimeInput from 'src/document/components/DateTimeInput'
import {
  BASE_METADATA_TYPES,
  metadataTimeTypes,
} from 'src/document/types/FolderMetadataTypes'
import { IDocumentType } from 'src/document/types/IFlow'
import { IMetaValue } from 'src/document/types/IMetaData'
import { TestExecutionStatus } from 'src/page/systematic-completion/tests/TestExecutionList'
import { getProjectControlAreas } from 'src/service/ControlAreaService'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import { getProjectMilestones } from 'src/service/MilestoneService'
import {
  IControlArea,
  IDiscipline,
  IImprovement,
  IKeypoint,
  IMilestone,
  IRoom,
  IStatus,
  IStatusTypes,
  ISystem,
  ISystemFMIGroup,
  ISystemFMIStatusMapper,
  ISystemMMI,
  ISystemType,
  ISystemTypeGroup,
  ISystemTypeUnique,
  ITag,
  ITaskType,
  ITestType,
  ITestWorkGroup,
  ITrainType,
  IUserData,
} from 'src/service/OrgTypes'
import {
  getProjectKeypoints,
  getProjectProcesses,
  IMainProcessResponse,
} from 'src/service/ProcessService'
import { getRoomsForSelector } from 'src/service/RoomService'
import { getSystems } from 'src/service/SystemService'
import { actionSource } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getProjectTaskTypes } from 'src/service/TaskService'
import { getProjectUsersWithDisciplines } from 'src/service/UserService'
import { styleClass } from 'src/ui-elements/Table/Columns'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { ISingleFilter } from 'src/ui-elements/list/ListContextProvider'
import {
  filterCase,
  filterType,
  IListColumns,
} from 'src/ui-elements/list/ListTypes'
import {
  IStringDropDownItem,
  TDropDownType,
} from 'src/ui-elements/page-display/inline-components/IDropDown'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import MultiSelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/MultiSelectorInlineInputComponent'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { StatusEnum } from 'src/utility/statusEnums'
import { classNames, openFileExternal } from 'src/utility/utils'
import MetaDataFieldsForPage from '../../document/components/DocumentCreateModal/MetaDataFieldsForPage'
import DocumentTypeService from '../../document/services/DocumentTypeService'
import useListHelper from '../../ui-elements/list/UseListHelper'
import SelectorInlineInputComponent from '../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TableDatePicker from '../datepicker/TableDatePicker'
import TableCleanStatusLabel from '../status-dropdown/TableCleanStatusLabel'
import TableStatusLabel, {
  IStatusCell,
} from '../status-dropdown/TableStatusLabel'
import TestTableStatusLabel from '../status-dropdown/TestTableStatusLabel'
import CheckBox from '../switchHoc/CheckBox'
import { getMetaDataValues, metaFiledToMetaValue } from '../system/SystemUtil'
import { IMetaField } from '../system/system-type-fields/SystemTypeFieldsForm'
import TableSelectorEditor from '../table-ResponsibleEditor/TableSelectorEditor'
import TableTextEditor from '../table-texteditor/TableTextEditor'
import UserIcon from '../user/UserIcon'

export interface ItemSelect {
  items: {
    record_id?: string
    id: number
  }[]
  systemIds: number[]
}

export const RecordIdColumn = (columnSize: string) => ({
  name: 'id',
  size: columnSize,
  id: 'record_id',
  sortingField: 'record_id',
  dataField: 'record_id',
  filterType: filterType.TEXT,
  filter: [],
})

export const TitleColumn = (
  fieldName: string,
  columnSize: string,
  onTitleChange: (key: number, value: string) => void,
  id = 'title',
  disableEdit?: boolean,
) => ({
  name: id,
  size: columnSize,
  id: fieldName,
  dataField: fieldName,
  sortingField: fieldName,
  filter: [],
  filterType: filterType.TEXT,
  cell: (cell: string, key: number) => (
    <TableTextEditor
      value={cell}
      clickToEdit={!disableEdit}
      onSubmit={(value: string) => onTitleChange(key, value)}
    />
  ),
})

export const BooleanColumn = (
  title: string,
  fieldName: string,
  columnSize: string,
  onChange: (key: number, value: boolean) => void,
  disabled?: boolean,
  hidden?: boolean,
) => ({
  name: title,
  size: columnSize,
  id: fieldName,
  dataField: fieldName,
  sortingField: fieldName,
  filterType: filterType.DEFAULT,
  filterCase: filterCase.ACTION_YES_NO,
  disabled: disabled,
  hidden: hidden,
  filter: [
    {
      name: 'yes',
      value: 'Ja',
      active: false,
    },
    {
      name: 'no',
      value: 'Nei',
      active: false,
    },
  ],
  cell: (value: boolean, key: number) => (
    <div className={'pl-2 flex items-center'}>
      <CheckBox onChange={() => onChange(key, !value)} valueProp={value} />
    </div>
  ),
})

export const TextColumn = (
  title: string,
  fieldName: string,
  columnSize: string,
  onChange: (key: number, value: string) => void,
  disableEdit?: boolean,
  type?: string,
  disabled?: boolean,
  hidden?: boolean,
  disableSorting?: boolean,
  disableFilter?: boolean,
) => ({
  name: title,
  size: columnSize,
  id: title,
  dataField: fieldName,
  sortingField: disableSorting ? undefined : fieldName,
  filter: disableFilter ? undefined : [],
  filterType: filterType.TEXT,
  disabled: disabled,
  hidden: hidden,
  cell: (cell: string, key: number) => (
    <TableTextEditor
      value={cell}
      disableEdit={!!disableEdit}
      type={type}
      clickToEdit={true}
      onSubmit={(value) => onChange(key, value)}
    />
  ),
})

export const FileNameColumn = (
  name: string,
  id: string,
  fieldName: string,
  columnSize: string,
  disabled?: boolean,
) => ({
  name: name,
  size: columnSize,
  id: id,
  dataField: fieldName,
  filterType: filterType.TEXT,
  disabled: disabled,
  cell: (cell: { file_url: string; document_name: string }) =>
    cell.file_url ? (
      <a
        onClick={openFileExternal(cell.file_url)}
        className="cursor-pointer underline text-blue-one mr-2 truncate w-mn-content"
        rel="noreferrer"
      >
        {cell.document_name}
      </a>
    ) : (
      <>{cell.document_name}</>
    ),
})

export const ImageTitleColumn = (
  title: string,
  fieldName: string,
  columnSize: string,
  onTitleChange: (key: number, value: string) => void,
  disableEdit?: boolean,
  type?: string,
  disabled?: boolean,
) => ({
  name: title,
  size: columnSize,
  id: title,
  dataField: fieldName,
  filterType: filterType.TEXT,
  disabled: disabled,
  cell: (
    cell: { name: string; url?: string; file_url?: string },
    key: number,
  ) => (
    <TableTextEditor
      value={cell.name}
      url={cell.url || cell.file_url}
      disableEdit={!!disableEdit}
      type={type}
      clickToEdit={true}
      onSubmit={(value) => onTitleChange(key, value)}
    />
  ),
})

export const UpdateResponsilbeColumnOnly = (
  projectId: number,
  onResponsibleChange: (key: number, responsibleId: number) => void,
  disableEdit: boolean,
) => {
  return {
    name: 'responsible',
    size: '200',
    id: 'responsible',
    dataField: 'responsible',
    cell: (responsible: IUserData, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={responsible}
        getData={() => getProjectUsersWithDisciplines(projectId)}
        dataFields={['firstName', 'lastName']}
        isUserSelector={true}
        showCancelButton={true}
        disableEdit={disableEdit}
        onDataSelected={(value) => onResponsibleChange(key, value.id)}
        displayContent={
          !responsible ? (
            <span></span>
          ) : (
            <UserIcon
              userId={responsible.id}
              firstName={responsible.firstName}
              lastName={responsible.lastName}
              image_url={responsible.image_url}
            />
          )
        }
      />
    ),
  }
}

export const StatusColumn = (
  dataFieldNames: string,
  status: IStatusTypes[],
  onStatusSelect: (
    status: IStatusTypes,
    key: number,
    cell: IStatusCell,
  ) => void,
  selectColor?: (cell: IStatusCell) => BadgeColor,
) => ({
  name: 'status',
  size: '200',
  id: 'status',
  sortingField: 'status',
  dataField: dataFieldNames,
  filterType: filterType.DEFAULT,
  filterCase: filterCase.ACTION_STATUS,
  filterDataField: 'status',
  filterDataValue: 'status',
  defaultFilter: {
    filterType: filterType.DEFAULT,
    filterDataField: 'status',
    filterDataValue: 'status',
    activeFilter: [],
  },
  filter: [
    {
      name: 'open',
      value: 'open',
      active: false,
    },
    {
      name: 'in_progress',
      value: 'in_progress',
      active: false,
    },
    {
      name: 'done',
      value: 'done',
      active: false,
    },
  ],
  cell: (cell: IStatusCell, key: number) => (
    <TableStatusLabel
      statusTypes={status}
      cell={cell}
      rowId={key}
      onStatusSelect={onStatusSelect}
      showDropdownAlways={true}
      selectColor={selectColor}
    />
  ),
})

export const CleanStatusColumn = (
  dataFieldNames: string,
  status: IStatusTypes[],
  onStatusSelect: (status: IStatusTypes, key: number) => void,
) => ({
  name: 'rtb_status',
  size: '160',
  id: 'rtb_status',
  sortingField: 'rtb_status',
  dataField: dataFieldNames,
  filterType: filterType.DEFAULT,
  filterCase: filterCase.ACTION_STATUS,
  filterDataField: 'rtb_status',
  filterDataValue: 'rtb_status',
  defaultFilter: {
    filterType: filterType.DEFAULT,
    filterDataField: 'rtb_status',
    filterDataValue: 'rtb_status',
    activeFilter: [],
  },
  filter: [
    {
      id: 'not_clean_zone',
      value: 'not_clean_zone',
      name: 'not_clean_zone',
      active: false,
    },
    {
      id: 'red_clean_zone',
      name: 'red_clean_zone',
      value: 'red_clean_zone',
      active: false,
    },
    {
      id: 'yellow_clean_zone',
      name: 'yellow_clean_zone',
      value: 'yellow_clean_zone',
      active: false,
    },
  ],
  cell: (cell: { rtb_status: string; status: string }, key: number) => (
    <TableCleanStatusLabel
      statusTypes={status}
      cell={cell}
      rowId={key}
      onStatusSelect={onStatusSelect}
      showDropdownAlways={true}
    />
  ),
})

export const CreatedAtColumn = (hidden?: boolean) => ({
  name: 'created_at',
  size: '120',
  id: 'created_at',
  dataField: 'created_at',
  sortingField: 'created_at',
  filterType: filterType.RANGEDATE,
  filter: [],
  hidden: hidden,
  cell: (created?: string) => (
    <span>{created ? moment(created).format('L') : ''}</span>
  ),
})

export const UpdatedAtColumn = (hidden?: boolean) => ({
  name: 'updated_at',
  size: '120',
  id: 'updated_at',
  dataField: 'updated_at',
  sortingField: 'updated_at',
  filterType: filterType.RANGEDATE,
  filter: [],
  hidden: hidden,
  cell: (updated?: string) => (
    <span>{updated ? moment(updated).format('L') : ''}</span>
  ),
})

export const BaselineColumn = {
  name: 'baseline_date',
  size: '120',
  id: 'baseline',
  sortingField: 'baseline',
  dataField: 'baseline',
  dateFormat: 'DD.MM.YYYY',
  filterType: filterType.RANGEDATE,
  filter: [],
  cell: (baseline?: string) => (
    <span>{baseline ? moment(baseline).format('L') : ''}</span>
  ),
}

export const DurationColumn = {
  name: 'duration_days',
  size: '130',
  id: 'duration',
  sortingField: 'duration',
  dataField: 'duration',
  filter: [],
  filterType: filterType.NUMBER,
  cell: (duration: number) => <div className={styleClass.cell}>{duration}</div>,
}

export const DelayColumn = {
  name: 'delay_days',
  size: '130',
  id: 'delay',
  sortingField: 'delay',
  dataField: 'delay',
  filterType: filterType.NUMBER,
  filter: [],
  cell: (delay: number) => (
    <div className={twMerge(delay && 'text-red-600', styleClass.cell)}>
      {delay}
    </div>
  ),
}

export const ClosedColumn = {
  name: 'closed_date',
  size: '120',
  id: 'closed_date',
  sortingField: 'closed_date',
  dataField: 'closed_date',
  filter: [],
  filterType: filterType.RANGEDATE,
  hidden: true,
  cell: (closedDate?: string) => (
    <div className={styleClass.cell}>
      {closedDate ? moment(closedDate).format('L') : ''}
    </div>
  ),
}

export const DeliveriesColumn = {
  name: 'deliveries',
  size: '150',
  id: 'delivery',
  dataField:
    'done_children||open_children||expired_children||behind_schedule_children',
  cell: (keypoint: {
    done_children: number
    open_children: number
    expired_children: number
    behind_schedule_children: number
  }) => (
    <div
      className={classNames(
        styleClass.cell,
        (keypoint.expired_children > 0 ||
          keypoint.behind_schedule_children > 0) &&
          'text-red-two',
      )}
    >
      {keypoint.done_children}/{keypoint.open_children + keypoint.done_children}
    </div>
  ),
}

export const KeypointsColumn = {
  name: 'keypoints',
  size: '150',
  id: 'keypoint',
  dataField:
    'done_children||open_children||expired_children||behind_schedule_children',
  cell: (milestone: {
    done_children: number
    open_children: number
    expired_children: number
    behind_schedule_children: number
  }) => (
    <span
      className={classNames(
        styleClass.cell,
        (milestone.expired_children > 0 ||
          milestone.behind_schedule_children > 0) &&
          'text-red-two',
      )}
    >
      {milestone.done_children}/
      {milestone.open_children + milestone.done_children}
    </span>
  ),
}

export const TasksColumn = {
  name: 'tasks',
  size: '150',
  id: 'open_children',
  dataField: 'done_children||open_children||expired_children',
  sortingField: 'open_children',
  cell: (delivery: {
    done_children: number
    open_children: number
    expired_children: number
  }) => (
    <span
      className={classNames(
        styleClass.cell,
        delivery.expired_children > 0 && 'text-red-two',
      )}
    >
      {delivery.done_children}/{delivery.done_children + delivery.open_children}
    </span>
  ),
}

export const MainprocessColumn = (
  projectId: number,
  onFieldChange: (key: number, mainprocessId: string) => void,
  disabled?: boolean,
) => {
  const { getProcessFilter } = useListHelper()
  return {
    name: 'main_process',
    size: '300',
    id: 'main_process',
    sortingField: 'main_process',
    dataField: 'main_process',
    filterType: filterType.DEFAULT,
    filterDataField: 'main_process.name',
    filterDataValue: 'main_process.id',
    disabled: disabled,
    getFilter: getProcessFilter,
    filter: [],
    cell: (process: IMainProcessResponse, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={process}
        getData={() => getProjectProcesses(projectId)}
        dataFields={['name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={
          process ? <div className="truncate">{process.name}</div> : <span />
        }
      />
    ),
  }
}

export const MilestoneColumn = (
  projectId: number,
  onFieldChange: (key: number, milestoneId: string) => void,
) => {
  const { getMilestoneFilter } = useListHelper()
  return {
    name: 'milestone',
    size: '280',
    id: 'mile_stone',
    sortingField: 'mile_stone',
    dataField: 'mile_stone',
    filterType: filterType.DEFAULT,
    filterDataField: 'mile_stone.name',
    filterDataValue: 'mile_stone.id',
    filter: [],
    getFilter: getMilestoneFilter,
    cell: (milestone: IMilestone, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={milestone}
        getData={() => getProjectMilestones(projectId)}
        dataFields={['name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={
          milestone ? (
            <div className="truncate">{milestone.name}</div>
          ) : (
            <span />
          )
        }
      />
    ),
  }
}

/**
 * @deprecated Use MultiFilterOptionsColumnEditable instead
 */
export const MilestoneColumnUnedited = () => {
  const { getMilestoneFilter } = useListHelper()
  return {
    name: 'milestone',
    size: '280',
    id: 'mile_stone',
    sortingField: 'mile_stone',
    dataField: 'mile_stone',
    filterType: filterType.DEFAULT,
    filterDataField: 'mile_stone.name',
    filterDataValue: 'mile_stone.id',
    filter: [],
    getFilter: getMilestoneFilter,
    cell: (milestone: IMilestone) => (
      <div className={styleClass.cell}>
        {milestone ? milestone.record_id + ' - ' + milestone.name : ''}
      </div>
    ),
  }
}

/**
 * @deprecated Use MultiFilterOptionsColumnEditable instead
 */
export const KeypointColumn = (
  projectId: number,
  onFieldChange: (key: number, milestoneId: string) => void,
) => {
  const { getKeypointFilter } = useListHelper()
  return {
    name: 'keypoint',
    size: '240',
    id: 'key_point',
    dataField: 'key_point',
    sortingField: 'key_point',
    filterType: filterType.DEFAULT,
    filterDataField: 'key_point.name',
    filterDataValue: 'key_point.id',
    getFilter: getKeypointFilter,
    filter: [],
    cell: (data: IKeypoint, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={data}
        getData={() => getProjectKeypoints(projectId)}
        dataFields={['name']}
        isUserSelector={false}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={
          <div className="truncate">
            {data ? data.record_id + ' - ' + data.name : ''}
          </div>
        }
      />
    ),
  }
}

export const TagColumn = (
  projectId: number,
  onFieldChange: (key: number, milestoneId: string) => void,
  disabled?: boolean,
) => {
  const { getTagFilter } = useListHelper()
  return {
    name: 'type',
    size: '160',
    id: 'tag',
    dataField: 'tag',
    sortingField: 'tag',
    filterType: filterType.DEFAULT,
    filterDataField: 'tag.name',
    filterDataValue: 'tag.id',
    filter: [],
    disabled: disabled,
    getFilter: getTagFilter,
    cell: (tag: ITag, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={tag}
        getData={() => getProjectTags(projectId)}
        dataFields={['name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={<div className="truncate">{tag ? tag.name : ''}</div>}
      />
    ),
  }
}

export const TaskTypeColumn = (
  projectId: number,
  onFieldChange: (key: number, milestoneId: string) => void,
  disabled?: boolean,
) => {
  const { getTaskTypeFilter } = useListHelper()
  return {
    name: 'task_type',
    size: '160',
    id: 'task_type',
    dataField: 'task_type',
    sortingField: 'task_type',
    filterType: filterType.DEFAULT,
    filterDataField: 'task_type.taskTypeName',
    filterDataValue: 'task_type.id',
    filter: [],
    disabled: disabled,
    getFilter: getTaskTypeFilter,
    cell: (taskType: ITaskType, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={taskType}
        getData={() => getProjectTaskTypes(projectId)}
        dataFields={['taskTypeName']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={
          <div className="truncate">{taskType?.taskTypeName ?? ''}</div>
        }
      />
    ),
  }
}

export const DocumentTypeColumn = (
  onFieldChange: (key: number, documentTypeId: number) => void,
  disableSorting: boolean,
  disableFilter: boolean,
  disableEdit?: boolean,
) => {
  const { getDocumentTypeFilter } = useListHelper()
  return {
    name: 'document_type',
    size: '200',
    id: 'document_type',
    dataField: 'document_type',
    sortingField: disableSorting ? undefined : 'document_type',
    filterType: filterType.DEFAULT,
    filterDataField: 'document_type.name',
    filterDataValue: 'document_type.id',
    filter: disableFilter ? undefined : [],
    getFilter: getDocumentTypeFilter,
    cell: (documentType: IDocumentType, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={documentType}
        getData={() => DocumentTypeService.getProjectDocumentTypes()}
        dataFields={['name']}
        disableEdit={disableEdit}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={
          <div className="truncate">
            {documentType ? documentType.name : ''}
          </div>
        }
      />
    ),
  }
}

export const ImprovementColumn = (
  projectId: number,
  onFieldChange: (key: number, improvementId: string) => void,
  disabled?: boolean,
) => {
  const { getImprovementFilter } = useListHelper()
  return {
    name: 'improvement',
    size: '200',
    id: 'improvement',
    dataField: 'improvement',
    sortingField: 'improvement',
    filterType: filterType.DEFAULT,
    filterDataField: 'improvement.title',
    filterDataValue: 'improvement.id',
    filter: [],
    disabled: disabled,
    getFilter: getImprovementFilter,
    cell: (improvement: IImprovement, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={improvement}
        getData={() =>
          getFilteredProjectImprovementsWithPagination(projectId).then(
            (res) => res.items,
          )
        }
        dataFields={['title']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={
          <div className="truncate">{improvement ? improvement.title : ''}</div>
        }
      />
    ),
  }
}
export const ControlAreaColumn = (
  projectId: number,
  onFieldChange: (key: number, controlAreaId: string) => void,
  disabled?: boolean,
) => {
  const { getControlAreaFilter } = useListHelper()
  return {
    name: 'control_area',
    size: '240',
    id: 'control_area',
    dataField: 'control_area',
    sortingField: 'control_area',
    filterType: filterType.DEFAULT,
    filter: [],
    disabled: disabled,
    getFilter: getControlAreaFilter,
    filterDataField: 'control_area.title',
    filterDataValue: 'control_area.id',
    cell: (controlArea: IControlArea, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={controlArea}
        getData={() => getProjectControlAreas(projectId)}
        dataFields={['title']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={
          <div className="truncate">{controlArea ? controlArea.title : ''}</div>
        }
      />
    ),
  }
}

export const RoomColumn = (
  projectId: number,
  onFieldChange: (key: number, roomId: string) => void,
  disabled?: boolean,
) => {
  const { getRoomFilter } = useListHelper()
  return {
    name: 'room',
    size: '240',
    id: 'location',
    dataField: 'location',
    sortingField: 'location',
    filterType: filterType.DEFAULT,
    filterDataField: 'location.room_name',
    filterDataValue: 'location.id',
    disabled: disabled,
    getFilter: getRoomFilter,
    filter: [],
    cell: (room: IRoom, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={room}
        getData={() => getRoomsForSelector(projectId)}
        dataFields={['room_name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={
          <div className="truncate">{room ? room.room_name : ''}</div>
        }
      />
    ),
  }
}
export const ReporterColumn = (fieldName: string, hidden?: boolean) => {
  const { getReporterFilter } = useListHelper()
  return {
    name: 'reporter',
    size: '160',
    id: fieldName,
    dataField: fieldName,
    sortingField: fieldName,
    filterType: filterType.DEFAULT,
    filterDataField: `${fieldName}.firstName||${fieldName}.lastName`,
    filterDataValue: `${fieldName}.id`,
    hidden: hidden,
    getFilter: getReporterFilter,
    filter: [],
    cell: (user: IUserData) =>
      user ? (
        <div className={styleClass.cell}>
          <UserIcon
            userId={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            image_url={user.image_url}
          />
        </div>
      ) : (
        <span />
      ),
  }
}

export const DeadlineColumn = (
  fieldName: string,
  dataFieldNames: string,
  onDateChange: (date: moment.Moment, key: number) => void,
  name?: string,
  disableEdit?: boolean,
) => ({
  name: name ?? 'deadline',
  size: '100',
  id: fieldName,
  sortingField: fieldName,
  dataField: dataFieldNames,
  filterType: filterType.RANGEDATE,
  filter: [],
  cell: (data: string, row: number) => (
    <TableDatePicker
      date={data[fieldName]}
      rowId={row}
      onDateSubmit={onDateChange}
      disabled={!!disableEdit}
    />
  ),
})

export interface EditableDatePickerOptions {
  disabled?: boolean
  nullable?: boolean
  deadline?: boolean
}

export const EditableDateColumn = (
  name: string,
  fieldName: string,
  dataFieldNames: string,
  onDateChange: (date: moment.Moment | null, key: number) => void,
  options?: EditableDatePickerOptions,
) => ({
  name: name,
  size: '200',
  id: fieldName,
  sortingField: fieldName,
  dataField: dataFieldNames,
  filterType: filterType.RANGEDATE,
  filter: [],
  cell: (data: string, row: number) => {
    return (
      <div className={twMerge(options?.nullable && 'group', 'w-full h-full')}>
        <DateTimeInput
          inputClassName={styleClass.editableCell}
          date={data ?? ''}
          onSave={(date) => onDateChange(date ? moment(date) : null, row)}
          cancelButton={options?.nullable}
          disabled={options?.disabled}
          deadline={options?.deadline}
          setErrorMessage={() => {}}
        />
      </div>
    )
  },
})

export const SystemNumberColumn = () => ({
  name: 'system_number',
  size: '300',
  id: 'system_id',
  dataField: 'system',
  sortingField: 'system_id',
  cell: (system: ISystem) => {
    return (
      <>
        <MaterialIcon
          icon={system.has_children ? 'workspaces' : 'circle'}
          className={classNames(
            'text-d-blue material-icons-outlined',
            system.has_children
              ? 'text-base h-6 w-6 mt-1'
              : 'text-xxs h-2 w-2 mr-3',
          )}
        />
        <span className={system.has_children ? 'font-bold' : ''}>
          {system.record_id}
        </span>
      </>
    )
  },
})

export const SystemRecordIDColumn = () => ({
  name: 'system_number',
  id: 'record_id',
  size: '150',
  dataField: 'record_id||has_children',
  sortingField: 'record_id',
  filter: [],
  filterType: filterType.TEXT,
  cell: (data: { record_id?: string; has_children?: boolean }) => (
    <div className="flex items-center">
      {data.has_children ? (
        <Workspaces className="text-d-blue material-icons-outlined text-base h-4 w-4 mr-2" />
      ) : (
        <Circle className="text-d-blue material-icons-outlined text-xxs h-3 w-3 mr-2" />
      )}
      <span className={data.has_children ? 'font-bold' : ''}>
        {data.record_id}
      </span>
    </div>
  ),
})

export const SystemColumn = (
  projectId: number,
  onFieldChange: (key: number, milestoneId: string) => void,
  disabled?: boolean,
) => {
  const { getSystemFilter } = useListHelper()
  return {
    name: 'system',
    size: '200',
    id: 'system',
    sortingField: 'system',
    dataField: 'system',
    filterType: filterType.DEFAULT,
    filterDataField: 'system.name',
    filterDataValue: 'system.id',
    disabled: disabled,
    getFilter: getSystemFilter,
    filter: [],
    cell: (system: ISystem, key: number) => (
      <TableSelectorEditor
        rowId={key}
        selected={system}
        getData={() => getSystems(projectId)}
        dataFields={['record_id']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => onFieldChange(key, value.id)}
        displayContent={
          <div className="truncate">
            {system ? `${system.record_id} ${system.name}` : ''}
          </div>
        }
      />
    ),
  }
}

export const SystemMmiColumnEditable = (
  updateMmI: (row: number, val: number | null) => void,
  getMMi: () => Promise<ISystemMMI[]>,
  disabled?: boolean,
  disableEdit?: boolean,
) => {
  const { getSystemMmiFilter } = useListHelper()
  return {
    name: 'system_mmi',
    size: '200',
    id: 'system_mmi',
    dataField: 'system_mmi||id',
    sortingField: 'system_mmi',
    filterType: filterType.DEFAULT,
    filterDataField: 'system_mmi.name',
    filterDataValue: 'system_mmi.id',
    getFilter: getSystemMmiFilter,
    filter: [],
    disabled: disabled,
    cell: (data: { system_mmi?: ISystemMMI; id: number }) => (
      <TableSelectorEditor
        rowId={data.id}
        selected={data.system_mmi}
        getData={() => getMMi()}
        dataFields={['name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) =>
          updateMmI(data.id, value.id ? value.id : null)
        }
        displayContent={<div className="truncate">{data.system_mmi?.name}</div>}
        disableEdit={disableEdit}
      />
    ),
  }
}

export const SystemFmiColumn = (disabled?: boolean) => {
  const { getSystemFmiFilter } = useListHelper()
  return {
    name: 'system_fmi',
    size: '200',
    id: 'system_fmi_status_mapper',
    dataField: 'system_fmi_status_mapper',
    sortingField: 'system_fmi_status_mapper',
    filterType: filterType.DEFAULT,
    filterFiled: 'system_fmi_status_mapper',
    filterDataField: 'system_fmi_status_mapper.approved_fmi',
    getFilter: getSystemFmiFilter,
    filter: [],
    disabled: disabled,
    cell: (system_fmi_status_mapper: ISystemFMIStatusMapper) =>
      system_fmi_status_mapper ? (
        <div className="truncate px-1">
          {system_fmi_status_mapper.approved_fmi}
        </div>
      ) : (
        <span />
      ),
  }
}

export const SystemFmiGroupColumnEditable = (
  updateFmiGroup: (row: number, val: number | null) => void,
  getFmiGroup: () => Promise<ISystemFMIGroup[]>,
  disabled?: boolean,
  disableEdit?: boolean,
) => {
  const { getSystemFmiGroupFilter } = useListHelper()
  return {
    name: 'system_fmi_group',
    size: '200',
    id: 'system_fmi_group',
    dataField: 'system_fmi_group',
    sortingField: 'system_fmi_group',
    filterType: filterType.DEFAULT,
    filterDataField: 'system_fmi_group.name',
    filterDataValue: 'system_fmi_group.id',
    getFilter: getSystemFmiGroupFilter,
    filter: [],
    disabled: disabled,
    cell: (system_fmi_group: ISystemFMIGroup, id: number) => (
      <TableSelectorEditor
        rowId={id}
        selected={system_fmi_group}
        getData={() => getFmiGroup()}
        dataFields={['name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => updateFmiGroup(id, value.id ?? null)}
        displayContent={
          <div className="truncate">{system_fmi_group?.name}</div>
        }
        disableEdit={disableEdit}
      />
    ),
  }
}
export const SystemTypeGroupColumn = (disabled?: boolean) => {
  const { getSystemTypeGroupFilter } = useListHelper()
  return {
    name: 'metadata',
    id: 'system_type_group',
    size: '200',
    dataField: 'system_type_group',
    sortingField: 'system_type_group',
    filter: [],
    filterType: filterType.DEFAULT,
    filterDataField: 'system_type_group.name',
    filterDataValue: 'system_type_group.id',
    disabled: disabled,
    getFilter: getSystemTypeGroupFilter,
    cell: (item?: ISystemTypeGroup) => (
      <div className="truncate">{item ? item.name : ''}</div>
    ),
  }
}

export const SystemTypeColumn = (
  systemSettingId?: number,
  disabled?: boolean,
) => {
  const { getSystemTypeFilter } = useListHelper()
  return {
    name: 'system_type',
    size: '200',
    id: 'system_type',
    dataField: 'system_type',
    sortingField: 'system_type',
    filterType: filterType.DEFAULT,
    filterDataField: 'system_type.type_code',
    filterDataValue: 'system_type.id',
    getFilter: () => getSystemTypeFilter(systemSettingId),
    filter: [],
    disabled: disabled,
    cell: (type?: ISystemType) =>
      type ? (
        <div className="truncate px-1">
          {type.type_code + ' - ' + type.name}
        </div>
      ) : (
        <span />
      ),
  }
}

export const SystemTypeUniqueColumn = (disabled?: boolean) => {
  const { getSystemTypeUniqueFilter } = useListHelper()
  return {
    name: 'system_type_unique',
    size: '200',
    id: 'system_type_unique',
    dataField: 'system_type_unique',
    filterType: filterType.DEFAULT,
    filterDataField: 'system_type_unique.type_code',
    filterDataValue: 'system_type_unique.id',
    getFilter: () => getSystemTypeUniqueFilter(),
    filter: [],
    disabled: disabled,
    cell: (type?: ISystemTypeUnique) =>
      type ? (
        <div className="truncate px-1">
          {type.type_code + ' - ' + type.name}
        </div>
      ) : (
        <span />
      ),
  }
}

export const SystemStatusColumn = (disabled?: boolean) => {
  const { getSystemStatusFilter } = useListHelper()
  return {
    name: 'system_status',
    size: '200',
    id: 'system_status',
    dataField: 'system_status',
    sortingField: 'system_status',
    filterType: filterType.DEFAULT,
    filterDataField: 'system_status.name',
    filterDataValue: 'system_status.id',
    getFilter: getSystemStatusFilter,
    filter: [],
    disabled: disabled,
    cell: (status: IStatus) => (
      <Badge
        status={StatusEnum[status?.status.toUpperCase()]}
        text={status?.name}
      />
    ),
  }
}

export const SystemStatusColumnEditable = (
  updateStatus: (row: number, val: number | null) => void,
  getStatuses: () => Promise<IStatus[]>,
  disableEdit?: boolean,
  disabled?: boolean,
) => {
  const { getSystemStatusFilter } = useListHelper()
  return {
    name: 'system_status',
    size: '200',
    id: 'system_status',
    dataField: 'system_status||id',
    sortingField: 'system_status',
    filterType: filterType.DEFAULT,
    filterDataField: 'system_status.name',
    filterDataValue: 'system_status.id',
    getFilter: getSystemStatusFilter,
    filter: [],
    disabled: disabled,
    cell: ({ system_status, id }: { system_status: IStatus; id: number }) => {
      return (
        <TableSelectorEditor
          rowId={id}
          selected={system_status}
          getData={() => getStatuses()}
          dataFields={['name']}
          isUserSelector={false}
          showCancelButton={true}
          onDataSelected={(value) =>
            updateStatus(id, value.id ? value.id : null)
          }
          displayContent={
            <Badge
              status={StatusEnum[system_status?.status.toUpperCase()]}
              text={system_status?.name}
            />
          }
          disableEdit={disableEdit}
        />
      )
    },
  }
}

export const TestSystemGroupStatusColumn = (
  getStatus: () => Promise<IStatus[]>,
  update: (row: number, val: number | null) => void,
  disableEdit?: boolean,
) => {
  const { getTestSystemGroupStatusFilter } = useListHelper()
  return {
    name: 'status',
    size: '280',
    id: 'status',
    sortingField: 'test_system_group_status',
    dataField: 'test_system_group_status||id',
    filterType: filterType.DEFAULT,
    filterDataField: 'test_system_group_status.name',
    filterDataValue: 'test_system_group_status.id',
    filter: [],
    getFilter: getTestSystemGroupStatusFilter,
    cell: ({
      test_system_group_status: status,
      id,
    }: {
      test_system_group_status: IStatus
      id: number
    }) => (
      <TableSelectorEditor
        rowId={id}
        selected={status?.id}
        getData={() => getStatus()}
        dataFields={['name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => update(id, value.id ? value.id : null)}
        displayContent={
          <Badge
            status={StatusEnum[status?.status.toUpperCase()]}
            text={status?.name}
          />
        }
        disableEdit={disableEdit}
      />
    ),
  }
}

export const TestWorkGroupStatusColumn = (
  getStatus: () => Promise<IStatus[]>,
  update: (row: number, val: number | null) => void,
  disableEdit?: boolean,
) => {
  const { getTestWorkGroupStatusFilter } = useListHelper()
  return {
    name: 'status',
    size: '280',
    id: 'test_work_group_status',
    sortingField: 'test_work_group_status',
    dataField: 'test_work_group_status||id',
    filterType: filterType.DEFAULT,
    filterDataField: 'test_work_group_status.name',
    filterDataValue: 'test_work_group_status.id',
    filter: [],
    getFilter: getTestWorkGroupStatusFilter,
    cell: ({
      test_work_group_status: status,
      id,
    }: {
      test_work_group_status: IStatus
      id: number
    }) => (
      <TableSelectorEditor
        rowId={id}
        selected={status?.id}
        getData={() => getStatus()}
        dataFields={['name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) => update(id, value.id ? value.id : null)}
        displayContent={
          <Badge
            status={StatusEnum[status?.status.toUpperCase()]}
            text={status?.name}
          />
        }
        disableEdit={disableEdit}
      />
    ),
  }
}

export const TestSystemGroupsColumn = (
  onEditClick?: (e: React.MouseEvent, data: ItemSelect) => void,
  disabled?: boolean,
) => {
  const { getTestSystemGroupFilter } = useListHelper()
  return {
    name: 'test_system_groups',
    size: '220',
    id: 'test_system_group',
    dataField: 'test_system_groups',
    filterFiled: 'test_system_group',
    filterType: filterType.DEFAULT,
    filterDataValue: 'test_system_group.id',
    filterDataField: 'test_system_groups->record_id',
    enableSorting: false,
    getFilter: getTestSystemGroupFilter,
    filter: [],
    disabled: disabled,
    cell: (
      testSystemGroups: { record_id: string; id: number }[],
      id: number,
    ) => {
      return (
        <div
          onClick={(e) =>
            onEditClick?.(e, {
              items: testSystemGroups,
              systemIds: [id],
            })
          }
          className={twMerge(
            'flex items-center w-full h-full truncate px-1',
            onEditClick && styleClass.editableCell,
          )}
        >
          {testSystemGroups
            ?.map((testSystemGroup) => `${testSystemGroup.record_id}`)
            ?.join(', ')}
        </div>
      )
    },
  }
}

export const DisciplinesColumnEditable = (
  getDisciplines: () => Promise<IDiscipline[]>,
  updateDisciplines: (row: number, dIds: TDropDownType[]) => void,
  disabled?: boolean,
  disableEdit?: boolean,
) => {
  const { getDisciplineFilter } = useListHelper()
  return {
    name: 'disciplines',
    size: '250',
    id: 'disciplines',
    dataField: 'disciplines||id',
    filterType: filterType.DEFAULT,
    filterDataField: 'disciplines->shortName',
    filterDataValue: 'discipline.id',
    filter: [],
    disabled: disabled,
    getFilter: getDisciplineFilter,
    cell: (data: { disciplines: IDiscipline[]; id: number }) => (
      <InlineComponentsWrapper inputWidth={'w-full'}>
        <MultiSelectorInlineInputComponent
          getItems={() => getDisciplines()}
          onValueSubmitted={(disciplineIds) => {
            if (disciplineIds !== undefined)
              updateDisciplines(data.id, disciplineIds)
          }}
          selectedIds={
            data.disciplines?.map((item: IDiscipline) => item.id) ?? []
          }
          initialItems={data.disciplines}
          getItemLabel={(discipline: IDiscipline) =>
            discipline ? `${discipline.shortName} - ${discipline.name}` : ''
          }
          cancelButton={true}
          disabled={disableEdit}
        />
      </InlineComponentsWrapper>
    ),
  }
}

export const DisciplinesColumn = (disabled?: boolean) => {
  const { getDisciplineFilter } = useListHelper()
  return {
    name: 'disciplines',
    size: '250',
    id: 'disciplines',
    dataField: 'disciplines',
    filterType: filterType.DEFAULT,
    filterDataField: 'discipline.shortName',
    filterDataValue: 'discipline.id',
    filterFiled: 'disciplines',
    filter: [],
    disabled: disabled,
    getFilter: getDisciplineFilter,
    cell: (disciplines: IDiscipline[]) => (
      <span className={'truncate'}>
        {disciplines
          ?.map((discipline) => `${discipline?.shortName} ${discipline?.name}`)
          .join(',')}
      </span>
    ),
  }
}

export const DisciplineColumnEditable = (
  getDisciplines: () => Promise<IDiscipline[]>,
  updateDisciplines: (row: number, val: number | null) => void,
  disabled?: boolean,
) => {
  const { getDisciplineFilter } = useListHelper()
  return {
    name: 'discipline',
    size: '250',
    id: 'discipline',
    dataField: 'discipline||id',
    sortingField: 'discipline',
    filterType: filterType.DEFAULT,
    filterDataField: 'discipline.shortName',
    filterDataValue: 'discipline.id',
    filter: [],
    disabled: disabled,
    getFilter: getDisciplineFilter,
    cell: (data: { discipline?: IDiscipline; id: number }) => (
      <TableSelectorEditor
        rowId={data.id}
        selected={data.discipline}
        getData={() => getDisciplines()}
        dataFields={['name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) =>
          updateDisciplines(data.id, value.id ? value.id : null)
        }
        displayContent={
          <div className="truncate">
            {data.discipline
              ? `${data.discipline.shortName} - ${data.discipline.name}`
              : ''}
          </div>
        }
      />
    ),
  }
}

export const TestWorkGroupColumn = (_disabled?: boolean) => {
  const { getTestWorkGroupFilter } = useListHelper()
  return {
    name: 'test_work_group',
    size: '200',
    id: 'test_work_group',
    dataField: 'test_work_group',
    sortingField: 'test_work_group',
    filterType: filterType.DEFAULT,
    filterDataField: 'test_work_group.record_id',
    filterDataValue: 'test_work_group.id',
    getFilter: getTestWorkGroupFilter,
    filter: [],
    disabled: true,
    cell: (item?: ITestWorkGroup) =>
      item ? (
        <div className="truncate">
          {item.record_id} - {item.title}
        </div>
      ) : (
        <span />
      ),
  }
}

export const TestWorkGroupsColumn = (disabled?: boolean) => {
  const { getTestWorkGroupFilter } = useListHelper()
  return {
    name: 'test_work_groups',
    size: '200',
    id: 'test_work_group',
    dataField: 'test_work_groups',
    filterType: filterType.DEFAULT,
    filterFiled: 'test_work_group',
    filterDataField: 'test_work_groups->record_id',
    filterDataValue: 'test_work_group.id',
    enableSorting: false,
    getFilter: getTestWorkGroupFilter,
    filter: [],
    disabled: disabled,
    cell: (testWorkGroups: ITestWorkGroup[]) => {
      return (
        <div className={'flex items-center w-full h-full truncate px-1'}>
          {testWorkGroups
            ?.map((group: ITestWorkGroup) => `${group.record_id ?? ''}`)
            ?.join(', ')}
        </div>
      )
    },
  }
}

export const CreatedByColumn = () => {
  const { getUserFilterWithReporter } = useListHelper()
  return {
    name: 'created_by',
    size: '200',
    id: 'created_by',
    sortingField: 'created_by',
    dataField: 'created_by',
    filterType: filterType.DEFAULT,
    filterDataField: 'created_by.firstName||created_by.lastName',
    filterDataValue: 'created_by.id',
    getFilter: getUserFilterWithReporter,
    filter: [],
    cell: (createdBy?: IUserData) =>
      createdBy ? (
        <UserIcon
          userId={createdBy.id}
          firstName={createdBy.firstName}
          lastName={createdBy.lastName}
          image_url={createdBy.image_url}
        />
      ) : (
        <span />
      ),
  }
}

export const UpdatedByColumn = () => {
  const { getUserFilterWithReporter } = useListHelper()
  return {
    name: 'updated_by',
    size: '200',
    id: 'updated_by',
    dataField: 'updated_by',
    filterType: filterType.DEFAULT,
    filterDataField: 'updated_by.firstName||updated_by.lastName',
    filterDataValue: 'updated_by.id',
    getFilter: getUserFilterWithReporter,
    filter: [],
    cell: (updated_by?: IUserData) =>
      updated_by ? (
        <UserIcon
          userId={updated_by.id}
          firstName={updated_by.firstName}
          lastName={updated_by.lastName}
          image_url={updated_by.image_url}
        />
      ) : (
        <span />
      ),
  }
}

const onFieldValueChange = (
  value: string | boolean | undefined,
  metaValue: IMetaValue,
) => {
  return { ...metaValue, data_value: `${value}` } as IMetaValue
}

const filterTypes = (meta: BASE_METADATA_TYPES) => {
  switch (meta) {
    case 'boolean':
      return filterType.DEFAULT
    case 'time':
      return filterType.RANGEDATE
    case 'string':
      return filterType.TEXT
    default:
      return filterType.DEFAULT
  }
}

const getEnumFilter = (pattern: string[]) => {
  const updatePattern = pattern.map((p) => {
    return {
      name: p,
      value: p,
      active: false,
    }
  })
  return updatePattern
}

// replace optional filed with new value
const replaceOptionalField = (field: IMetaValue[], value: IMetaValue) => {
  const index = field.findIndex(
    (f) => f.user_defined_field_id === Number(value.user_defined_field_id),
  )
  if (index !== -1) {
    field[index] = value
  } else {
    field.push(metaFiledToMetaValue(value))
  }
  return field
}

export const userDefinedColumns = (
  userDefinedField: IMetaField[],
  update: (
    systemTypeField: IMetaField,
    parentId: number,
    index?: number,
    of?: IMetaValue[],
  ) => void,
  dataFiled = 'optional_fields',
  sort = true,
  enableEmpty = false,
  disableEdit = false,
) => {
  const res: IListColumns[] = []
  if (!userDefinedField) return res
  for (const field of userDefinedField) {
    const data_key =
      field.data_type === BASE_METADATA_TYPES.multiEnum
        ? 'data_value_list'
        : 'data_value'
    if (!field.id) continue
    res.push({
      size: '220',
      id: `meta_data_${field.id}`,
      name: field.name,
      dataField: dataFiled,
      filterDataField: `meta_data['${field.id}']['${data_key}']`,
      filterType: filterTypes(field.data_type),
      userDefinedFiled: `${field.id}`,
      userDefinedType: field.data_type,
      sortingField:
        sort && field.data_type !== BASE_METADATA_TYPES.multiEnum
          ? `${field.id}`
          : undefined,
      processFilter: (filterValue) => ({
        name: String(field.id),
        data_type: field.data_type,
        search_param:
          field.data_type === 'string' && !Array.isArray(filterValue)
            ? [filterValue]
            : field.data_type === 'time' && !Array.isArray(filterValue)
              ? Object.values(filterValue)
              : filterValue,
      }),
      filter:
        field.data_type === BASE_METADATA_TYPES.multiEnum
          ? Array.isArray(field.pattern)
            ? getEnumFilter(field.pattern)
            : []
          : field.data_type === BASE_METADATA_TYPES.boolean
            ? [
                {
                  name: t('yes'),
                  value: 'true',
                  active: false,
                },
                {
                  name: t('no'),
                  value: 'false',
                  active: false,
                },
              ]
            : field.data_type === BASE_METADATA_TYPES.enum &&
                Array.isArray(field.pattern)
              ? getEnumFilter(field.pattern)
              : [],
      cell: (
        requiredFields: IMetaValue[] | Record<string, IMetaValue>,
        parentId: number,
        index: number,
      ) => {
        const fields = requiredFields
          ? Array.isArray(requiredFields)
            ? requiredFields
            : getMetaDataValues(requiredFields)
          : []
        const fieldValue =
          fields
            .reverse()
            .find(
              (v: IMetaValue) => v.user_defined_field_id === Number(field.id),
            ) ?? (enableEmpty ? (field as IMetaValue) : null)
        if (fieldValue) {
          const onFieldChangeFn = (value?: string | boolean) => {
            const _updatedData = onFieldValueChange(value, fieldValue)
            update(
              _updatedData,
              parentId,
              index,
              replaceOptionalField(fields, _updatedData),
            )
          }
          const onFieldValueUpdate = (list?: string[]) => {
            const _updatedData = {
              ...fieldValue,
              data_value_list: list,
            }
            update(
              _updatedData,
              parentId,
              index,
              replaceOptionalField(fields, _updatedData),
            )
          }
          if (field.data_type === BASE_METADATA_TYPES.time) {
            return (
              <div className={styleClass.editableCell}>
                <DateTimeInput
                  includeTime={isEqual(
                    field.pattern,
                    metadataTimeTypes['dateTime'].pattern,
                  )}
                  date={`${fieldValue.data_value ?? ''}`}
                  onSave={onFieldChangeFn}
                  disabled={disableEdit}
                />
              </div>
            )
          } else if (field.data_type === BASE_METADATA_TYPES.boolean) {
            return (
              <div
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                }}
                className={'pl-2 flex items-center'}
              >
                <CheckBox
                  disable={disableEdit}
                  disableTab={true}
                  onChange={onFieldChangeFn}
                  valueProp={
                    fieldValue.data_value === 'true' ||
                    fieldValue.data_value === true
                  }
                />
              </div>
            )
          } else if (field.data_type === BASE_METADATA_TYPES.string) {
            return (
              <TableTextEditor
                value={fieldValue.data_value as string}
                clickToEdit
                disableEdit={disableEdit}
                onSubmit={onFieldChangeFn}
              />
            )
          } else if (field.data_type === BASE_METADATA_TYPES.enum) {
            return (
              <InlineComponentsWrapper inputWidth={'w-full'}>
                <SelectorInlineInputComponent<string>
                  items={
                    Array.isArray(fieldValue.pattern)
                      ? fieldValue.pattern
                          .sort((patternA, patternB) =>
                            patternA?.localeCompare(patternB),
                          )
                          .map((pattern: string) => ({
                            id: pattern,
                            name: pattern,
                          }))
                      : []
                  }
                  onValueSubmitted={onFieldChangeFn}
                  getItemLabel={(fieldValue) => fieldValue?.id}
                  selectedId={fieldValue?.data_value as string}
                  initialItem={{ id: fieldValue.data_value as string }}
                  cancelButton={true}
                  inspectorPanel={false}
                  disabled={disableEdit}
                />
              </InlineComponentsWrapper>
            )
          } else if (field.data_type === BASE_METADATA_TYPES.multiEnum) {
            return (
              <InlineComponentsWrapper inputWidth={'w-full'}>
                <MultiSelectorInlineInputComponent<string, IStringDropDownItem>
                  items={
                    Array.isArray(fieldValue.pattern)
                      ? fieldValue.pattern
                          .sort((patternA, patternB) =>
                            patternA?.localeCompare(patternB),
                          )
                          .map((pattern: string) => ({
                            id: pattern.toLowerCase(),
                            name: pattern,
                          }))
                      : []
                  }
                  onValueSubmitted={onFieldValueUpdate}
                  getItemLabel={(fieldValue) => fieldValue?.name ?? ''}
                  selectedIds={
                    fieldValue?.data_value_list?.map((data) =>
                      data.toLowerCase(),
                    ) ?? []
                  }
                  initialItems={
                    fieldValue?.data_value_list
                      ? fieldValue?.data_value_list
                          .sort((dataA, dataB) => dataA?.localeCompare(dataB))
                          .map((data: string) => ({
                            id: data.toLowerCase(),
                            name: data,
                          }))
                      : []
                  }
                  cancelButton={true}
                  disabled={disableEdit}
                />
              </InlineComponentsWrapper>
            )
          }

          return <div className="truncate">{fieldValue.data_value}</div>
        } else {
          return <span />
        }
      },
    })
  }
  return res
}

export const TestTypeColumn = (disabled: boolean, hidden?: boolean) => {
  const { getTestTypeFilter } = useListHelper()
  return {
    name: 'test_type',
    size: '200',
    id: 'testTypeId',
    sortingField: 'test_type_id',
    dataField: 'test_type',
    filterDataField: 'test_type.name',
    filterDataValue: 'test_type.id',
    filter: [],
    filterType: filterType.DEFAULT,
    getFilter: getTestTypeFilter,
    disabled: disabled,
    hidden: hidden,
    cell: (testtype?: ITestType) =>
      testtype ? <div className="truncate">{testtype.name}</div> : <span />,
  }
}

export const TestStatusColumn = (
  disabled: boolean,
  statusTypes: IStatusTypes[],
  onStatusSelect: (status: IStatusTypes, key: number) => void,
  disableEdit?: boolean,
) => {
  return {
    name: 'status',
    size: '200',
    id: 'status',
    sortingField: 'status',
    dataField: 'status||execution_date',
    disabled: disabled,
    filterType: filterType.DEFAULT,
    filter: Object.values(TestExecutionStatus).map((status) => {
      return {
        name: status,
        value: status,
        active: false,
      }
    }),
    cell: (
      cell: { status: IStatusTypes; execution_date: moment.Moment },
      key: number,
    ) => (
      <TestTableStatusLabel
        statusTypes={statusTypes}
        cell={cell}
        rowId={key}
        onStatusSelect={onStatusSelect}
        disableEdit={disableEdit}
      />
    ),
  }
}

export const deleteColumn = (
  removeItem: (id: number, rowIndex: number) => void,
) => ({
  size: '50',
  id: 'delete',
  name: '',
  dataField: 'id',
  cell: (id: number, _: number, index: number) => {
    return (
      <span
        onClick={() => removeItem(id, index)}
        className={
          'mx-1 flex items-center justify-center bg-white rounded-full w-5 h-5 p-0.5 hover:cursor-pointer bg-blue-100'
        }
      >
        <Delete />
      </span>
    )
  },
})

export const TrainTypeColumn = () => {
  const { getTrainTypeFilter } = useListHelper()
  return {
    name: 'train',
    size: '200',
    id: 'train_type',
    dataField: 'train_type',
    sortingField: 'train_type',
    filterType: filterType.DEFAULT,
    filter: [],
    getFilter: getTrainTypeFilter,
    filterDataField: 'train_type',
    filterDataValue: 'train_type',
    cell: (d?: ITrainType) => (d ? <span>{d.title}</span> : <span />),
  }
}

export const WagonTypeColumn = () => {
  const { getWagonTypeFilter } = useListHelper()
  return {
    name: 'wagon',
    size: '200',
    id: 'locomotive_type',
    dataField: 'locomotive_type',
    sortingField: 'locomotive_type',
    filterType: filterType.DEFAULT,
    filter: [],
    getFilter: getWagonTypeFilter,
    filterDataField: 'locomotive_type',
    filterDataValue: 'locomotive_type',
    cell: (d?: ITrainType) =>
      d ? <span className="overflow-hidden">{d.title}</span> : <span />,
  }
}

export const draftRecordIdColumn = (
  draftMode: boolean,
  t: TFunction<'translation', undefined>,
) => ({
  name: 'record_id',
  size: '300',
  id: 'record_id',
  dataField: 'record_id||draft_id',
  sortingField: 'record_id',
  filter: [],
  filterType: filterType.TEXT,
  cell: ({
    record_id,
    draft_id,
  }: {
    record_id: string
    draft_id: number | null
  }) => (
    <span className="flex">
      {record_id}{' '}
      {draft_id !== null && draftMode && (
        <Tooltip
          message={t('drafts_are_filtered_and_sorted_based_on_published_value')}
        >
          <p className="text-red first-capitalize ml-1">{t('draft')}</p>
        </Tooltip>
      )}
    </span>
  ),
})

export const metaDataSection = (
  optionalFields: IMetaValue[],
  reload: () => void,
  disabled?: boolean,
) => {
  const rows = []
  for (let i = 0; i < optionalFields.length; i += 9) {
    const endIndex = i + 9
    rows.push(
      <MetaDataFieldsForPage
        onFieldsUpdate={reload}
        fields={optionalFields.slice(i, endIndex)}
        required={false}
        editMode={false}
        border={endIndex < optionalFields.length ? 'right' : undefined}
        inputWidth={'w-64'}
        inspectorPanel={false}
        disabled={disabled}
      />,
    )
  }

  return rows
}

export const sourceColumn = (
  t: TFunction<'translation', undefined>,
  filterOptions?: ISingleFilter[],
) => ({
  name: 'source',
  size: '180',
  id: 'parentType',
  dataField: 'parent_type',
  sortingField: 'parent_type',
  filterType: filterType.DEFAULT,
  filterCase: filterCase.ACTION_SOURCE,
  filterDataField: 'parent_type',
  filterDataValue: 'parent_type',
  filter: filterOptions ?? [
    ...actionSource(t).map((source) => ({
      ...source,
      value: source.id,
      active: true,
    })),
  ],
  cell: (parent: string) => (
    <span>
      {actionSource(t)
        .filter((pt) => pt.id === parent)
        .map((pt) => pt.name)
        .pop()}
    </span>
  ),
})
